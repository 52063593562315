import { ClientAccount, Cohort, CustomPathway, ExtendedCohort } from '../../interfaces';
import { UserPathwayAssignment } from '../../interfaces/user/user-pathway';
import { deleteUserHistoryAppcues } from '../../services/appcues.service';
import { baseApiSlice } from './base-api-slice';

const adminApiSlice = baseApiSlice
  .enhanceEndpoints({ addTagTypes: ['ClientList', 'AllCohorts', 'UserList', 'CMSPathways'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getClientList: builder.query<ClientAccount[], void>({
        query: () => 'client/get-clients',
        transformResponse: (response: { data: ClientAccount[] }) => response.data,
        providesTags: ['ClientList'],
      }),
      getAllCohorts: builder.query<ExtendedCohort[], void>({
        query: () => 'cohort/get-cohorts',
        transformResponse: (response: { data: ExtendedCohort[] }) => response.data,
        providesTags: ['AllCohorts'],
      }),
      getCMSPathways: builder.query<CustomPathway[], void>({
        query: () => 'cohort/get-pathways',
        transformResponse: (response: { data: CustomPathway[] }) => response.data,
        providesTags: ['CMSPathways'],
      }),
      getClientCustomUserFields: builder.query<Partial<ClientAccount>, { clientId: string }>({
        query: ({ clientId }) => `client/get-client-custom-user-fields?clientId=${clientId}`,
        transformResponse: (response: { data: Partial<ClientAccount> }) => response.data,
      }),
      createClient: builder.mutation<
        ClientAccount,
        Pick<ClientAccount, 'name' | 'seats' | 'link' | 'clientLeads' | 'customUserFields'>
      >({
        query(body) {
          return {
            url: 'client/create-client',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: ClientAccount }) => response.data,
        async onQueryStarted(_updateArgs, { dispatch, queryFulfilled }) {
          const { data: newClient } = await queryFulfilled;
          dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === newClient.id);
              if (index > -1) {
                const currentEntity = clientList[index];
                Object.assign(clientList[index], {
                  ...currentEntity,
                  ...newClient,
                });
              } else {
                clientList.push(newClient);
              }
            }),
          );
        },
      }),
      createCohort: builder.mutation<
        Cohort,
        Pick<Cohort, 'name' | 'users' | 'instructors' | 'content'> & { clientId: string }
      >({
        query(body) {
          return {
            url: 'cohort/create-cohort',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: Cohort }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const { data: newCohort } = await queryFulfilled;
          dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.clientId);
              if (index > -1) {
                const currentClient = clientList[index];
                Object.assign(clientList[index], {
                  ...currentClient,
                  cohorts: [...currentClient.cohorts, newCohort],
                });
              }
            }),
          );
        },
      }),
      updateClientSeats: builder.mutation<
        ClientAccount,
        Pick<ClientAccount, 'id' | 'seats' | 'clientLeads' | 'accountAdmins'>
      >({
        query(body) {
          return {
            url: 'client/update-seats',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: ClientAccount }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.id);
              if (index > -1) {
                const currentClient = clientList[index];
                Object.assign(clientList[index], {
                  ...currentClient,
                  ...updateArgs,
                });
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
      updateClientRegistrationLink: builder.mutation<ClientAccount, Pick<ClientAccount, 'id' | 'link'>>({
        query(body) {
          return {
            url: 'client/update-registration-link',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: ClientAccount }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.id);
              if (index > -1) {
                const currentClient = clientList[index];
                Object.assign(clientList[index], {
                  ...currentClient,
                  ...updateArgs,
                });
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
      updateCustomUserFields: builder.mutation<ClientAccount, { clientId: string; customUserFields: object }>({
        query(body) {
          return {
            url: 'client/update-custom-user-fields',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: ClientAccount }) => response.data,
        invalidatesTags: ['ClientList'],
      }),
      updateClientDiscussionLink: builder.mutation<
        ClientAccount,
        Pick<ClientAccount, 'id' | 'discussionLink' | 'discussionLinkName'>
      >({
        query(body) {
          return {
            url: 'client/update-discussion-link',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: ClientAccount }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.id);
              if (index > -1) {
                const currentClient = clientList[index];
                Object.assign(clientList[index], {
                  ...currentClient,
                  ...updateArgs,
                });
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
      updateCohortContentAccess: builder.mutation<Cohort, Pick<Cohort, 'id' | 'content'> & { clientId: string }>({
        query({ id, content }) {
          return {
            url: 'cohort/set-content-access',
            method: 'POST',
            body: { id, content },
          };
        },
        transformResponse: (response: { data: Cohort }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.clientId);
              if (index > -1) {
                const currentClient = clientList[index];
                const cohortIndex = currentClient.cohorts.findIndex((cohort) => cohort.id === updateArgs.id);
                if (cohortIndex > -1) {
                  const currentCohort = currentClient.cohorts[cohortIndex];
                  Object.assign(currentClient.cohorts[cohortIndex], {
                    ...currentCohort,
                    content: updateArgs.content,
                  });
                }
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
      updateCohortPathwayAccess: builder.mutation<Cohort, Pick<Cohort, 'id' | 'pathway'> & { clientId: string }>({
        query({ id, pathway }) {
          return {
            url: 'cohort/set-pathways-access',
            method: 'POST',
            body: { id, pathway },
          };
        },
        transformResponse: (response: { data: Cohort }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.clientId);
              if (index > -1) {
                const currentClient = clientList[index];
                const cohortIndex = currentClient.cohorts.findIndex((cohort) => cohort.id === updateArgs.id);
                if (cohortIndex > -1) {
                  const currentCohort = currentClient.cohorts[cohortIndex];
                  Object.assign(currentClient.cohorts[cohortIndex], {
                    ...currentCohort,
                    pathway: updateArgs.pathway,
                  });
                }
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
      updateCohortUsers: builder.mutation<Cohort, { cohort: Cohort; clientId: string }>({
        query({ cohort }) {
          return {
            url: 'cohort/update-cohort-users',
            method: 'POST',
            body: { cohort },
          };
        },
        transformResponse: (response: { data: Cohort }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.clientId);
              if (index > -1) {
                const currentClient = clientList[index];
                const cohortIndex = currentClient.cohorts.findIndex((cohort) => cohort.id === updateArgs.cohort.id);
                if (cohortIndex > -1) {
                  const currentCohort = currentClient.cohorts[cohortIndex];
                  Object.assign(currentClient.cohorts[cohortIndex], {
                    ...currentCohort,
                    ...updateArgs,
                    users: updateArgs.cohort.users,
                  });
                }
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
        invalidatesTags: ['UserList'],
      }),
      updateCohortInstructors: builder.mutation<Cohort, { cohort: Cohort; clientId: string }>({
        query({ cohort }) {
          return {
            url: 'cohort/update-cohort-instructors',
            method: 'POST',
            body: { cohort },
          };
        },
        transformResponse: (response: { data: Cohort }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.clientId);
              if (index > -1) {
                const currentClient = clientList[index];
                const cohortIndex = currentClient.cohorts.findIndex((cohort) => cohort.id === updateArgs.cohort.id);
                if (cohortIndex > -1) {
                  const currentCohort = currentClient.cohorts[cohortIndex];
                  Object.assign(currentClient.cohorts[cohortIndex], {
                    ...currentCohort,
                    ...updateArgs,
                    instructors: updateArgs.cohort.instructors,
                  });
                }
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
      updateCohortName: builder.mutation<Cohort, Pick<Cohort, 'id' | 'name'> & { clientId: string }>({
        query({ id, name }) {
          return {
            url: 'cohort/rename-cohort',
            method: 'POST',
            body: { id, name },
          };
        },
        transformResponse: (response: { data: Cohort }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.clientId);
              if (index > -1) {
                const currentClient = clientList[index];
                const cohortIndex = currentClient.cohorts.findIndex((cohort) => cohort.id === updateArgs.id);
                if (cohortIndex > -1) {
                  const currentCohort = currentClient.cohorts[cohortIndex];
                  Object.assign(currentClient.cohorts[cohortIndex], {
                    ...currentCohort,
                    name: updateArgs.name,
                  });
                }
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
      updateCohortAssignment: builder.mutation<UserPathwayAssignment, UserPathwayAssignment>({
        query(body) {
          return {
            url: 'cohort/update-assignment',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: UserPathwayAssignment }) => response.data,
        invalidatesTags: ['AllCohorts'],
      }),
      updateCohortSummativeAssessment: builder.mutation<Cohort, UserPathwayAssignment>({
        query(body) {
          return {
            url: 'cohort/update-summative-assessment',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: Cohort }) => response.data,
        invalidatesTags: ['AllCohorts'],
      }),
      deleteClient: builder.mutation<void, Pick<ClientAccount, 'id'>>({
        query({ id: clientId }) {
          return {
            url: 'client/delete-client',
            method: 'DELETE',
            body: { clientId },
          };
        },
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const deleteResult = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.id);
              if (index > -1) {
                clientList.splice(index, 1);
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            deleteResult.undo();
          }
        },
        invalidatesTags: ['UserList'],
      }),
      deleteCohort: builder.mutation<void, Pick<Cohort, 'id'> & { clientId: string }>({
        query(body) {
          return {
            url: 'cohort/delete-cohort',
            method: 'DELETE',
            body: { id: body.id },
          };
        },
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const clearClients = dispatch(
            adminApiSlice.util.updateQueryData('getClientList', undefined, (clientList) => {
              const index = clientList.findIndex((client) => client.id === updateArgs.clientId);
              if (index > -1) {
                const currentClient = clientList[index];
                Object.assign(clientList[index], {
                  ...currentClient,
                  cohorts: currentClient.cohorts.filter((clientCohort) => clientCohort.id !== updateArgs.id),
                });
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            clearClients.undo();
          }
        },
        invalidatesTags: ['UserList', 'AllCohorts'],
      }),
      deleteUser: builder.mutation<void, { emails: string[]; appcuesApiKey: string }>({
        query({ emails }) {
          return {
            url: 'user/delete-user',
            method: 'DELETE',
            body: { email: emails },
          };
        },
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const clearUsers = dispatch(
            adminApiSlice.util.updateQueryData('getUserList', {}, (userList) => {
              for (const email of updateArgs.emails) {
                const index = userList.findIndex((user) => user.email === email);
                if (index > -1) {
                  userList.splice(index, 1);
                }
              }
            }),
          );
          try {
            await queryFulfilled;
            await deleteUserHistoryAppcues(updateArgs.emails, updateArgs.appcuesApiKey);
          } catch {
            clearUsers.undo();
          }
        },
        invalidatesTags: ['ClientList', 'UserList'],
      }),
      updateChecklistAccess: builder.mutation<
        void,
        { userIds: string[]; hasChecklistAccess: boolean; byCohort?: boolean; cohortId?: string }
      >({
        query({ userIds, hasChecklistAccess, byCohort, cohortId }) {
          return {
            url: 'cohort/update-checklist-access',
            method: 'POST',
            body: { userIds, hasChecklistAccess, byCohort, cohortId },
          };
        },
        invalidatesTags: ['UserList', 'ClientList'],
      }),
      generateRegistrationLink: builder.mutation<{ link: string; linkGeneratedAt: Date }, { userId: string }>({
        query({ userId }) {
          return {
            url: 'user/generate-registration-link',
            method: 'POST',
            body: { userId },
          };
        },
        transformResponse: (response: { data: { link: string; linkGeneratedAt: Date } }) => response.data,
        invalidatesTags: ['UserList', 'PaginatedUsers'],
      }),
    }),
  });

export const {
  useGetClientListQuery,
  useGetAllCohortsQuery,
  useGetCMSPathwaysQuery,
  useGetClientCustomUserFieldsQuery,
  useCreateClientMutation,
  useCreateCohortMutation,
  useUpdateClientSeatsMutation,
  useUpdateCustomUserFieldsMutation,
  useUpdateClientRegistrationLinkMutation,
  useUpdateClientDiscussionLinkMutation,
  useUpdateCohortContentAccessMutation,
  useUpdateCohortPathwayAccessMutation,
  useUpdateCohortUsersMutation,
  useUpdateCohortInstructorsMutation,
  useUpdateCohortNameMutation,
  useUpdateCohortAssignmentMutation,
  useUpdateCohortSummativeAssessmentMutation,
  useDeleteClientMutation,
  useDeleteCohortMutation,
  useDeleteUserMutation,
  useUpdateChecklistAccessMutation,
  useGenerateRegistrationLinkMutation,
} = adminApiSlice;
